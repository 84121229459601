import { services, contacts } from "../info";
import useWindowWidth from "../hooks/useWindowWidth";

const Footer = () => {
	const windowWidth = useWindowWidth();
	return (
		<div
			id="footer"
			className="w-full pt-11 pb-3 px-4 lg:pt-[76px] lg:pb-8 lg:px-[106px] bg-brand-blue text-white"
		>
			<div className="flex flex-col max-w-full gap-8 lg:flex-row lg:items-start lg:justify-between mb-[70px]">
				<div className="flex flex-col">
					<img
						className="w-[66px] mb-[10px]"
						src="/img/footer/footer-logo.svg"
						alt="Company logo"
					/>
					<p className="text-2xl font-bold mb-5">
						Abinne Projects Int'l {windowWidth > 960 && <br />}
						Limited
					</p>
					<a
						className="flex flex-row gap-4 animate-bounce"
						href={`https://wa.me/${contacts.whatsapp.replace(/\s/g, "")}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							className="w-7"
							src="/img/footer/whatsapp-icon.svg"
							alt="Whatsapp icon"
						/>
					</a>
				</div>

				<div className="flex flex-col gap-5 lg:gap-[33px]">
					<h2 className="text-3xl font-bold">OUR SERVICES</h2>
					<ul className="flex flex-col gap-2 lg:gap-5">
						{services.map((service, index) => {
							return (
								<li key={index}>
									<p className="text-base">{service.name}</p>
								</li>
							);
						})}
					</ul>
				</div>

				<div className="flex flex-col gap-5 lg:gap-[33px]">
					<h2 className="text-3xl font-bold">CONTACTS</h2>
					<div className="flex flex-col lg:flex-row items-start gap-5">
						<div className="flex flex-col gap-5">
							{contacts.phone.map((phone, index) => {
								return (
									<a
										key={index}
										className="flex flex-row gap-[10px]"
										href={`tel:${phone.replace(/\s/g, "")}`}
									>
										<img
											className="self-start w-[18px] animate-pulse"
											src={`img/footer/telephone-icon.svg`}
											alt="Phone icon"
										/>
										<p>{phone}</p>
									</a>
								);
							})}
							<a
								className="flex flex-row gap-[10px]"
								href={`mailto:${contacts.email}`}
							>
								<img
									className="w-[18px] animate-pulse"
									src="/img/footer/email-icon.svg"
									alt="Email icon"
								/>
								<p>{contacts.email}</p>
							</a>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<h2 className="text-3xl font-bold">LOCATIONS</h2>
					<div className="flex flex-col gap-8">
						{contacts.address.map((address, index) => {
							return (
								<div
									key={index}
									className="flex flex-row gap-[10px] items-start"
								>
									<img
										className="w-[22px]"
										src="/img/footer/location-icon.svg"
										alt="Location icon"
									/>
									<p className="whitespace-pre-line">{address}</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>

			<div className="w-full border-b border-[#8D8DD5] lg:mb-8"></div>
			<p className="text-center text-base py-[30px] lg:p-0">
				&#169; 2023 Abinne Projects ltd. All rights reserved.
			</p>
			{windowWidth < 960 && (
				<div className="max-w-screen border-b border-[#8D8DD5] lg:mb-8"></div>
			)}
		</div>
	);
};

export default Footer;
